import { useEffect, useState } from "react"

import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import InputImage from "components/forms/input-image"
import useBrandRepo from "repositories/brand"
import { getData } from "helpers/api-helper"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)
    const { data: brands } = useBrandRepo({
        page:1,
        is_active:1,
        paginate:99
    })

    const id = data?.id
    const [name, set_name] = useState(data?.name ?? null)
    const [brand, set_brand] = useState(data?.brand ?? null)
    const [model, set_model] = useState(data?.model ?? null)
    const [link_product, set_link_product] = useState(data?.link_product ?? null)
    const [device_type, set_device_type] = useState(data?.device_type ?? null)
    const [warranty_limit, set_warranty_limit] = useState(data?.warranty_limit ?? null)
    const [initial_warranty, set_initial_warranty] = useState(data?.initial_warranty ?? null)
    const [image, set_image] = useState(data?.image ?? null)

    const [specs,set_specs] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault()

        if(name === null || name === ''){
            toast.error("Name Master Product Required!")
        }else if(brand === null || brand === ''){
            toast.error("Brand Required!")
        }else if(model === null || model === ''){
            toast.error("Model Required!")
        }else if(device_type === null || device_type === ''){
            toast.error("Model Required!")
        }
        // else if(warranty_limit === null || warranty_limit === ''){
        //     toast.error("Warranty Limit Required!")
        // }
        else if(initial_warranty === null || initial_warranty === ''){
            toast.error("Initial Warranty Required!")
        }else if(image === null || image === ''){
            toast.error("Image Required!")
        }else{
            if (!nProgress.isStarted()) {
                onSubmit({
                    id,
                    name,
                    brand,
                    image,
                    model,
                    link_product,
                    device_type,
                    // warranty_limit,
                    specs,
                    initial_warranty,
                })
            } else {
                toast.error("Previous action is loading")
            }
        }
    }

    useEffect(() => {
        if(data?.id){
            getSpec(data?.device_type)
        }
    }, [])

    const changeDeviceType = (e) => {
        set_device_type(e.target.value)
        getSpec(e.target.value)
    }

    const getSpec= async (value)=>{
        var params = {spec_type:value,paginate:"false",is_active:1,id:data?.id}
        var url = 'ms-specification'
        if(data?.id){
            url = 'ms-product/get-product-specs';
        }
        const response = await getData(`${process.env.REACT_APP_API}/${url}`,params)

        if(response.data){
            if(data?.id){
                response.data.data.map((data,index) => {
                    data.product_specification_id = data.product_spec[0].id
                    data.value = data.product_spec[0].value ?? null
                })
            }
            set_specs(response.data.data)
        }
    }

    const set_spec_value=(index,value)=>{
        let new_specs = [...specs]
        new_specs[index].value = value
        set_specs(new_specs)
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="name" value={'Name'} />
                                        <Input placeholder="Input name" onChange={(e) => set_name(e.target.value)} value={name} id="name" error={errors.name} />
                                    </div>
                                    <div>
                                        <Label htmlFor="brand" value="Brand Code" />
                                        <select onChange={(e) => set_brand(e.target.value)} value={brand} name="brand" id="brand" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- Brand Code? --</option>
                                            {
                                                brands && brands.data.map((data,index) => 
                                                    <option value={data.brand_code} key={index}>{data.brand_name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <Label htmlFor="model" value={'Model'} />
                                        <Input disabled={data?.model} placeholder="Input Model" onChange={(e) => set_model(e.target.value)} value={model} id="model" />
                                    </div>
                                    <div>
                                        <Label htmlFor="device_type" value="Device Type" />
                                        <select onChange={(e) => changeDeviceType(e)} value={device_type} name="device_type" id="device_type" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- {lang.choose} Type --</option>
                                                <option value={'Android'}>{'Android'}</option>
                                                <option value={'Windows'}>{'Windows'}</option>
                                                <option value={'Chromebook'}>{'Chromebook'}</option>
                                                <option value={'Accessories'}>{'Accessories'}</option>
                                        </select>
                                    </div>
                                    {/* <div>
                                        <Label htmlFor="warranty_limit" value={'Warranty Limit'} />
                                        <Input placeholder="Input Warranty Limit" onChange={(e) => set_warranty_limit(e.target.value)} value={warranty_limit} id="warranty_limit" error={errors.warranty_limit} type="number"/>
                                    </div> */}
                                    <div>
                                        <Label htmlFor="initial_warranty" value={'Initial Warranty'} />
                                        <Input placeholder="Input Initial Warranty" onChange={(e) => set_initial_warranty(e.target.value)} value={initial_warranty} id="initial_warranty" error={errors.initial_warranty} type="number" />
                                    </div>
                                    <div>
                                        <Label htmlFor="link_product" value={'Link Product'} />
                                        <Input placeholder="Input Link Product" onChange={(e) => set_link_product(e.target.value)} value={link_product} id="link_product" />
                                    </div>
                                    <div>
                                        <Label htmlFor="image" value={'Image'} />
                                        <InputImage onChange={(value) => set_image(value)} aspect="video" id="image" name="image" src={image} error={errors.image} alt="https://placehold.co/400x400/F5F5F5/404040?font=source-sans-pro&text=400x400" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {   specs.length > 0 &&
                    <div className="md:grid md:grid-cols-3 md:gap-6 mt-5">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">{'Specification Form'}</h3>
                                <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                            </div>
                        </div>
                        <div className="mt-5 md:col-span-2 md:mt-0">
                            <div className="border sm:rounded-xl">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="grid grid-cols-2 gap-4">
                                        {
                                            specs.map((spec,index) =>
                                                <div key={index}>
                                                    <Label htmlFor={spec.code} value={spec.name} />
                                                    <Input placeholder={"Input "+spec.name} type={'text'} onChange={(e) => set_spec_value(index,e.target.value)} value={spec.value} id={spec.code} />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}