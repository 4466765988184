import { useEffect, useState } from "react"

import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import useAllOutlet from "repositories/all-outlet"
import useAllStatus from "repositories/get-all-status"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const { data: listDataOutlet } = useAllOutlet({})
    const { data: listDataStatus } = useAllStatus({})

    const id = data?.id
    const [reference_number, set_reference_number] = useState(data?.reference_number ?? null)
    const [serial_number, set_serial_number] = useState(data?.serial_number ?? null)
    const [outlet_service_id, set_outlet_service_id] = useState(data?.outlet_service_id ?? null)
    const [status, set_status] = useState(data?.status ?? null)
    const [notes, set_notes] = useState(data?.notes ?? null)
    const [is_done, set_is_done] = useState(0)
    const [current_number_order,set_current_number_order] = useState(0)

    useEffect(() => {
        if(data?.reference_number && listDataStatus && listDataStatus.length > 0){
            const ind = listDataStatus.findIndex(item => item.name === data?.status);
            set_current_number_order(listDataStatus[ind]?.number_order)
        }
    },[listDataStatus])

    const handleSubmit = (e) => {
        e.preventDefault()

        if(is_done && (notes === null || notes === "")){
            if (window.confirm('Are you sure you want to submit without notes?')) {
                // Save it!
                console.log('Thing was saved to the database.');
            } else {
                return
            }
        }

        if(serial_number === null || serial_number === ''){
            toast.error("Serial Number Required!")
        }else if(status === null || status === ''){
            toast.error("Status Required!")
        }else{
            if (!nProgress.isStarted()) {
                onSubmit({
                    id,
                    reference_number,
                    serial_number,
                    outlet_service_id,
                    status,
                    notes,
                    update:data?.status && status === data?.status ? 1 : 0,
                    is_done,
                })
            } else {
                toast.error("Previous action is loading")
            }
        }
    }

    const handleChangeStatus=(value)=>{
        const index = listDataStatus.findIndex(item => item.name === value)
        set_is_done(listDataStatus[index].number_order == listDataStatus.length ? 1 : 0)
        set_status(value)
    }

    const checkSerialNumber=()=>{
        window.open(process.env.REACT_APP_PWA_URL+"/check-sn?serial_number="+serial_number+"&forced=true", "_blank")
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="reference_number" value={'Reference Number'} />
                                        <Input disabled={data?.reference_number} placeholder="Input Reference Number (Leave it empty to auto generate)" onChange={(e) => set_reference_number(e.target.value)} value={reference_number} id="reference_number" error={errors.reference_number} />
                                    </div>
                                    <div>
                                        <div className="flex flex-row justify-between">
                                            <Label htmlFor="serial_number" value={'Serial Number'} />
                                            { serial_number && 
                                                <div className="cursor-pointer" onClick={() => checkSerialNumber()}>
                                                    <label htmlFor={'check_sn'} className="block text-xs text-blue-700">{'Check Serial Number'}</label>
                                                </div>
                                            }
                                        </div>
                                        <Input disabled={data?.reference_number} placeholder="Input Serial Number" onChange={(e) => set_serial_number(e.target.value)} value={serial_number} id="serial_number" error={errors.serial_number} />
                                    </div>
                                    <div>
                                        <Label htmlFor="outlet_service_id" value="Outlet Service" />
                                        <select disabled={data?.reference_number} onChange={(e) => set_outlet_service_id(e.target.value)} value={outlet_service_id} name="outlet_service_id" id="outlet_service_id" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- Outlet Service? --</option>
                                            {
                                                listDataOutlet && listDataOutlet.map((data,index) => 
                                                    <option value={data.id} key={index}>{data.name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <Label htmlFor="status" value="Status" />
                                        <select onChange={(e) => handleChangeStatus(e.target.value)} value={status} name="status" id="status" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- Status? --</option>
                                            {
                                                listDataStatus && listDataStatus.map((detail,index) => 
                                                    <option value={detail.name} key={index} disabled={detail.number_order != current_number_order+1}>{detail.name}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <Label htmlFor="notes" value={'Notes'} />
                                        <textarea type={'textarea'} placeholder="Input Notes" onChange={(e) => set_notes(e.target.value)} value={notes} id="notes" className="border-neutral-200 placeholder:text-neutral-500 file:border-solid file:border file:px-3 file:text-xs file:border-neutral-200 file:py-1 file:rounded-full block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}