import { useState } from "react"

import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import InputImage from "components/forms/input-image"
// import { convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, List, Font } from 'ckeditor5';
// import { SlashCommand } from 'ckeditor5-premium-features';

import 'ckeditor5/ckeditor5.css';
import Checkbox from "components/forms/checkbox"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const id = data?.id
    const [title, set_title] = useState(data?.title ?? null)
    const [description, set_description] = useState(data?.description ?? null)
    const [type, set_type] = useState(data?.type ?? null)
    const [source, set_source] = useState(data?.source ?? null)
    const [thumbnail, set_thumbnail] = useState(data?.thumbnail ?? null)
    // const [content_description, set_content_description] = useState(content_description_x ?? "");

    const handleSubmit = (e) => {
        e.preventDefault()
        if(title === null || title === ''){
            toast.error("Title FAQ Required!")
        }else{
            if (!nProgress.isStarted()) {
                onSubmit({
                    id,
                    title,
                    description,
                    type,
                    source,
                    thumbnail
                })
            } else {
                toast.error("Previous action is loading")
            }
        }
    }

    // const onEditorStateChange=(editorState)=>{
    //     console.log(editorState)
    //     set_content_description(editorState)
    //     set_description(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    // }

    const changeTextEditor=(editor)=>{
        console.log(editor.getData())
        set_description(editor.getData())
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                    <div className="my-2">
                                        <Label htmlFor="title" value={'Title'} />
                                        <Input placeholder="Input Title" onChange={(e) => set_title(e.target.value)} value={title} id="title" error={errors.title} />
                                    </div>
                                    <div className="my-2">
                                        <Label htmlFor="description" value={'Description'} />
                                        {/* <textarea type={'textarea'} placeholder="Input Description" onChange={(e) => set_description(e.target.value)} value={description} id="description" className="border-neutral-200 placeholder:text-neutral-500 file:border-solid file:border file:px-3 file:text-xs file:border-neutral-200 file:py-1 file:rounded-full block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200"/> */}
                                        {/* <Editor
                                            editorState={content_description}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                        /> */}
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            onChange={( event, editor ) => changeTextEditor(editor)}
                                            onInit = { editor => { 
                                                editor.setData(description)
                                             } }
                                            data={description ?? ""}
                                            config={ {
                                                fontFamily: {
                                                    options: [
                                                        'default', 'Arial',
                                                        'Times New Roman',
                                                        'Courier New','Georgia'
                                                    ]
                                                },
                                                toolbar: {
                                                    items: [ 
                                                        'undo', 'redo',
                                                        '|',
                                                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                                        '|',
                                                        'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                                        '|',
                                                        'link', 'uploadImage', 'blockQuote', 'codeBlock',
                                                        '|',
                                                        'alignment',
                                                        '|',
                                                        'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent' ],
                                                },
                                                plugins: [
                                                    Bold, Essentials, Italic, Mention, Paragraph, Undo, Font
                                                ],
                                                licenseKey: '<YOUR_LICENSE_KEY>',
                                                mention: { 
                                                    // Mention configuration
                                                },
                                                initialData: '',
                                            } }
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}