import { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom"

import moment from "moment/moment"
import Filter from "pages/devices/filter"
import nProgress from "nprogress"
import { useAuth } from "contexts/auth-context"
import { Pagination } from "components"
import { lang } from "config"
import { Check, Pencil, Plus, X } from "components/icons/outline"
import useDevicesRepositories from "repositories/useDevicesRepositories"
import axios from "axios"
import toast from "react-hot-toast"
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

export default function List() {
    // Transition
    const { title, transition, setTransition } = useOutletContext()

    // React router navigate hook
    const navigate = useNavigate()

    // React router location hook
    const location = useLocation()

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const searchInput = useRef()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const serial_number = searchParams.get('serial_number')
    const customer_code = searchParams.get('customer_code')
    const model = searchParams.get('model')
    const device_type = searchParams.get('device_type')
    const from = searchParams.get('from')
    const to = searchParams.get('to')
    const status = searchParams.get('status')

    const { data: listData, isLoading: isLoadingListData, mutate: mutateListData } = useDevicesRepositories({
        page,
        search,
        serial_number,
        customer_code,
        model,
        device_type,
        from,
        to,
        status
    })

    useEffect(() => {
        searchInput.current.focus()
    }, [search])

    useEffect(() => {
        window.history.replaceState({}, document.title)

        if (transition.reverse) {
            setTransition({ type: 'slide', reverse: false })
        }
    }, [])

    const public_path = ""

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoadingListData) {
            nProgress.start()
        } else {
            nProgress.done()
        }
       console.log(listData)
    }, [listData, isLoadingListData])

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    const statusRow=(expired_date)=>{
        if(expired_date >= getCurrentDate()){
            return ('text-green-800 bg-green-100')
        }{
            return ('text-red-800 bg-red-100')
        }
    }

    function getCurrentDate(separator = '-') {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let hours = newDate.getHours();
        let minutes = newDate.getMinutes();
        let seconds = newDate.getSeconds();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`} ${hours < 10 ? `0${hours}` : `${hours}`}:${minutes < 10 ? `0${minutes}` : `${minutes}`}:${seconds < 10 ? `0${seconds}` : `${seconds}`}`;
    }

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between text-xs">
                <div className="flex items-center sm:space-x-2">
                    <div className="relative hidden sm:block">
                        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx={10} cy={10} r={7}></circle>
                                <line x1={21} y1={21} x2={15} y2={15}></line>
                            </svg>
                        </div>
                        <input ref={searchInput} onChange={(e) => setSearchParams({ search: e.target.value })} value={searchParams.get('search')} type="text" placeholder="Search Device Name" autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                    </div>
                    <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ serial_number,customer_code,model,device_type, from, to,status }).filter(([_, v]) => v != null))} />
                </div>
                {/* <div className="flex items-center space-x-2">
                    <div>
                        <Link to="create" onClick={() => { setTransition({ type: 'slide', reverse: true }) }} state={{ back: title, from: location?.pathname, transition: { type: 'slide' } }} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                            <Plus className="w-4 h-4" stroke={1.5} />
                            <span>{lang.create}</span>
                        </Link>
                    </div>
                </div> */}
            </div>
            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                    <thead className="bg-neutral-50 rounded-t-3xl">
                        <tr>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Device Id</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Serial Number</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Customer Code</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Device Name</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Type</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Brand</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Model</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Registered Date</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Expired Date</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Status</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {/* When loading */}
                        {isLoadingListData && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.loading_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available */}
                        {listData?.data.length === 0 && !search && !isLoadingListData && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available on searching */}
                        {listData?.data.length === 0 && search && !isLoadingListData && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_result}
                                </td>
                            </tr>
                        )}

                        {listData?.data.length > 0 && listData.data.map((row) => (
                            <tr key={row.id}>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.device_id}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.serial_number}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.customer_code}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.device_name}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.device_type}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.brand}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.model}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {moment(row.register_date).format('dddd MMMM D, YYYY')}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {moment(row.expired_date).format('dddd MMMM D, YYYY')}
                                </td>
                                <td className="px-6 py-4 text-xs whitespace-nowrap">
                                    <span className={'inline-flex px-2 text-xs font-semibold leading-5 rounded-full ' + statusRow(row.expired_date)}>
                                        {row.expired_date >= getCurrentDate() ? 'Active' : 'Expired'}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination links={listData?.links} from={listData?.from} to={listData?.to} total={listData?.total} />
        </div>
    )
}