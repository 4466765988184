import { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom"

import moment from "moment/moment"
import Filter from "pages/ms-product/filter"
import nProgress from "nprogress"
import { Pagination } from "components"
import { lang } from "config"
import { Check, Pencil, Plus, X } from "components/icons/outline"
import axios from "axios"
import toast from "react-hot-toast"
import useProductRepo from "repositories/ms_product"

export default function List() {
    // Transition
    const { title, transition, setTransition } = useOutletContext()

    // React router navigate hook
    const navigate = useNavigate()

    // React router location hook
    const location = useLocation()

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const searchInput = useRef()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const name = searchParams.get('name')
    const brand = searchParams.get('brand')
    const model = searchParams.get('model')
    const device_type = searchParams.get('device_type')
    const is_active = searchParams.get('is_active')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    const { data: listData, isLoading: isLoadingListData, mutate: mutateListData } = useProductRepo({
        page,
        search,
        name,
        brand,
        model,
        device_type,
        is_active,
        from,
        to
    })

    useEffect(() => {
        searchInput.current.focus()
    }, [search])

    useEffect(() => {
        window.history.replaceState({}, document.title)

        if (transition.reverse) {
            setTransition({ type: 'slide', reverse: false })
        }
    }, [])

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoadingListData) {
            nProgress.start()
        } else {
            nProgress.done()
        }
       console.log(listData)
    }, [listData, isLoadingListData])

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    const statusRow=(status)=>{
        if(status === 1){
            return ('text-green-800 bg-green-100')
        }else if(status === 2){
            return ('text-red-800 bg-red-100')
        }
    }

    const changeStatus=(id,status)=>{
        axios.get(`${process.env.REACT_APP_API}/ms-product/change-status?id=`+id+"&status="+status).then((response) => {
            toast.success(response.data.message)
            mutateListData()
        }).catch((error) => {
            if (error.response.status === 422) {
            }
            nProgress.done()
            toast.error(error.response.data.message)
            console.log(error.response)
        })
    }

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between text-xs">
                <div className="flex items-center sm:space-x-2">
                    <div className="relative hidden sm:block">
                        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx={10} cy={10} r={7}></circle>
                                <line x1={21} y1={21} x2={15} y2={15}></line>
                            </svg>
                        </div>
                        <input ref={searchInput} onChange={(e) => setSearchParams({ search: e.target.value })} value={searchParams.get('search')} type="text" placeholder="Search Product" autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                    </div>
                    <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ name,brand,model,device_type,is_active, from, to }).filter(([_, v]) => v != null))} />
                </div>
                <div className="flex items-center space-x-2">
                    <div>
                        <Link to="create" onClick={() => { setTransition({ type: 'slide', reverse: true }) }} state={{ back: title, from: location?.pathname, transition: { type: 'slide' } }} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                            <Plus className="w-4 h-4" stroke={1.5} />
                            <span>{lang.create}</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                    <thead className="bg-neutral-50 rounded-t-3xl">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Image</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Name</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Brand</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Model</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Link Product</th>
                            {/* <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Warranty Limit</th> */}
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Initial Warranty</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Device Type</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Created Date</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Status</th>
                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {/* When loading */}
                        {isLoadingListData && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.loading_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available */}
                        {listData?.data.length === 0 && !search && !isLoadingListData && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available on searching */}
                        {listData?.data.length === 0 && search && !isLoadingListData && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_result}
                                </td>
                            </tr>
                        )}

                        {listData?.data.length > 0 && listData.data.map((row) => (
                            <tr key={row.id}>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    <img className={`object-contain border rounded-lg aspect-${'video'} ring ring-neutral-100`} width={150} src={row.image || "https://placehold.co/400x400/F5F5F5/404040?font=source-sans-pro&text=400x400"} />
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-900 whitespace-nowrap">
                                    {row.name}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.brand}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.model}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    <a target="_blank" href={row.link_product} ope>Source</a>
                                </td>
                                {/* <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.warranty_limit}
                                </td> */}
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.initial_warranty}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.device_type}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {moment(row.created_at).format('MMMM D, YYYY')}
                                </td>
                                <td className="px-6 py-4 text-xs whitespace-nowrap">
                                    <span className={'inline-flex px-2 text-xs font-semibold leading-5 rounded-full ' + statusRow(row.is_active)}>
                                        {row.is_active === 1 ? 'Active' : 'In Active'}
                                    </span>
                                </td>
                                <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                    <div class="inline-flex items-center space-x-2">
                                        <Link to={'create'} state={{ back: title, from: location?.pathname, transition: 'slide',data:row }} className="inline-flex items-center p-1 text-white transition rounded-full bg-green-600 active:hover:scale-90">
                                            <Pencil />
                                        </Link>
                                        <button onClick={() => changeStatus(row.id,row.is_active == 1 ? 2 : 1)} className={"inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90 " + statusRow(row.is_active == 1 ? 2 : 1)}>
                                            { row.is_active === 1 ? <X /> : <Check />}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination links={listData?.links} from={listData?.from} to={listData?.to} total={listData?.total} />
        </div>
    )
}