import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom"

import axios from "axios"
import { useAuth } from "contexts/auth-context"
import { useLayoutEffect } from "react"
import Form from "./form"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import { useSWRConfig } from "swr"
import { usePage } from "contexts/page-context"

export default function Create() {
    const { cache } = useSWRConfig()

    const { setTransition } = usePage()

    const { setTitle } = useOutletContext()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        setTransition(location.state?.transition)
        if(location.state?.data){
            setTitle(`Edit Service Status`)
        }

        getPrevRouteState()
    }, [])

    const handleSubmit = async(data) => {
        nProgress.start()

        toast.loading("Loading", {
            id: "submit"
        })
        if(location.state?.data){
            var url = 'service-status/update';
        }else{
            var url = 'service-status/create';
        }
        var form = new FormData()
        form.append('id',data.id)
        form.append('name',data.name)
        form.append('number_order',data.number_order)
        form.append('last_number',data.last_number)
        form.append('is_done',data.is_done)
        form.append('is_active',1)
        axios.post(`${process.env.REACT_APP_API}/`+url, form,{
            headers: {
                'content-type': 'multipart/form-data'
            }}).then((response) => {
            cache.clear()
            if(response.data.message == 'exists'){
                toast.error('Code Already Exists', {
                    id: "submit"
                })
                nProgress.done()
            }else{
                navigate({ pathname: '/service-status' })
                toast.success(response.data.message, {
                    id: "submit"
                })
            }
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nProgress.done()
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                },
                id: "submit"
            })
            console.log(error.response)
        })
    }

    return (
        <Form errors={errors} onSubmit={handleSubmit} data={location.state?.data}/>
    )
}