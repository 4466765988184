import { useState } from 'react'
import { Modal } from 'components'
import Label from 'components/forms/label'
import Input from 'components/forms/input'
import InputAmount from 'components/forms/input-amount'
import 'react-datepicker/dist/react-datepicker.css'
import InputDate from 'components/forms/input-date'
import moment from 'moment'
import { lang } from "config"
import useServiceDeviceDetailRepo from 'repositories/service-device-detail'

const Content = ({ data }) => {
    const { data: listData, isLoading: isLoadingListData, mutate: mutateListData } = useServiceDeviceDetailRepo({
        reference_number:data.reference_number,
        type:"on_service"
    })

    return (
        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
        <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
            <thead className="bg-neutral-50 rounded-t-3xl">
                <tr>
                    <th scope="col" className="px-3 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Reference Number</th>
                    <th scope="col" className="px-3 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Serial Number</th>
                    <th scope="col" className="px-3 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Outlet</th>
                    <th scope="col" className="px-3 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Notes</th>
                    <th scope="col" className="px-3 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Status</th>
                    <th scope="col" className="px-3 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Datetime</th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-neutral-200">
                {/* When loading */}
                {isLoadingListData && (
                    <tr className="text-center">
                        <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                            {lang.loading_data}
                        </td>
                    </tr>
                )}

                {/* When there are no list available */}
                {listData?.length === 0 && !isLoadingListData && (
                    <tr className="text-center">
                        <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                            {lang.no_data}
                        </td>
                    </tr>
                )}

                {/* When there are no list available on searching */}
                {listData?.length === 0 && !isLoadingListData && (
                    <tr className="text-center">
                        <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                            {lang.no_result}
                        </td>
                    </tr>
                )}

                {listData?.length > 0 && listData.map((row) => (
                    <tr key={row.id}>
                        <td class="px-3 py-4 text-xs text-neutral-900 whitespace-nowrap">
                            {row.reference_number}
                        </td>
                        <td class="px-3 py-4 text-xs text-neutral-900 whitespace-nowrap">
                            {row.serial_number}
                        </td>
                        <td class="px-3 py-4 text-xs text-neutral-900 whitespace-nowrap">
                            {row.outlet?.name}
                        </td>
                        <td class="px-3 py-4 text-xs text-neutral-900 whitespace-nowrap">
                            {row.notes}
                        </td>
                        <td class="px-3 py-4 text-xs text-neutral-900 whitespace-nowrap">
                            {row.status}
                        </td>
                        <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                            {moment(row.created_at).format('MMMM D, YYYY - HH:mm:ss')}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    )
}

const ViewDetail = ({ data, errors, onSubmit, onRemove }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (value) => {
        closeModal()
        onSubmit(value)
    }

    const handleRemove = () => {
        closeModal()
        onRemove()
    }

    return (
        <>
            <button onClick={openModal} className="inline-flex items-center p-1 text-white transition rounded-full bg-neutral-200 active:hover:scale-90">
                <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 0 24 24" fill="rgba(0,0,0,1)">
                    <path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z">
                    </path>
                </svg>
            </button>
            <Modal isOpen={isOpen} size='6xl' onClose={closeModal} title={`${lang.data}`} content={<Content errors={errors} onSubmit={handleSubmit} onRemove={handleRemove} data={data} />} />
        </>
    )
}

export default ViewDetail