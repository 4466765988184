import { useState } from "react"

import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import Checkbox from "components/forms/checkbox"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const id = data?.id
    const [name, set_name] = useState(data?.name ?? null)
    const [number_order, set_number_order] = useState(data?.number_order ?? null)
    const [is_done, set_is_done] = useState(data?.is_done ?? null)

    const handleSubmit = (e) => {
        e.preventDefault()

        if(name === null || name === ''){
            toast.error("Status Name Required!")
        }else{
            if (!nProgress.isStarted()) {
                onSubmit({
                    id,
                    name,
                    number_order,
                    last_number:data?.number_order,
                    is_done
                })
            } else {
                toast.error("Previous action is loading")
            }
        }
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="name" value={'Status Name'} />
                                        <Input placeholder="Input Status Name" onChange={(e) => set_name(e.target.value)} value={name} id="name" error={errors.name} />
                                    </div>
                                    <div>
                                        <Label htmlFor="number_order" value={'Number of Order'} />
                                        <Input type={'number'} placeholder="Input Number of Order" onChange={(e) => set_number_order(e.target.value)} value={number_order} id="number_order" error={errors.number_order} />
                                    </div>
                                    {/* <div>
                                        <Label htmlFor="device_type" value="Last Status?" />
                                        <div className="flex justify-evenly text-md my-5">
                                            <div className="flex items-end">
                                                <div>
                                                <Checkbox onChange={() => set_is_done(0)} checked={is_done == 0} id="no" name="no" />
                                                </div>
                                                <div className="ml-2">
                                                    <label htmlFor="no">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex items-end">
                                                <div>
                                                <Checkbox onChange={() => set_is_done(1)} checked={is_done == 1} id="yes" name="yes" />
                                                </div>
                                                <div className="ml-2">
                                                    <label htmlFor="yes">
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}