import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom"

import axios from "axios"
import { useAuth } from "contexts/auth-context"
import { useLayoutEffect } from "react"
import Form from "./form"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import { useSWRConfig } from "swr"
import { usePage } from "contexts/page-context"

export default function Create() {
    const { user, isSuper } = useAuth()
    const { cache } = useSWRConfig()

    const { setTransition } = usePage()

    const { setTitle } = useOutletContext()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        setTransition(location.state?.transition)
        if(location.state?.data){
            setTitle(`Edit FAQ`)
        }

        getPrevRouteState()
    }, [])

    const handleSubmit = async(data) => {
        nProgress.start()

        toast.loading("Loading", {
            id: "submit"
        })
        if(location.state?.data){
            var url = 'faq/update';
        }else{
            var url = 'faq/create';
        }
        var form = new FormData()
        form.append('id',data.id)
        form.append('title',data.title)
        form.append('description',data.description)
        form.append('type',data.type)
        form.append('source',data.source)
        // if(data.thumbnail.obj){
        //     const options = {
        //         maxSizeMB: 0.5,
        //         useWebWorker: true,
        //         alwaysKeepResolution:false
        //     }
        //     var imageName = new Date().getTime()+".png";
        //     console.log(data.thumbnail)
        //     const compressedFile = await imageCompression(data.thumbnail, options);
        //     const fileData = new File(
        //         [compressedFile],
        //         imageName,
        //     { type :'image/jpeg'})
        //     form.append('thumbnail',fileData ?? null)
        // }else{
            form.append('thumbnail',data.thumbnail)
        // }
        axios.post(`${process.env.REACT_APP_API}/`+url, form,{
            headers: {
                'content-type': 'multipart/form-data'
            }}).then((response) => {
            cache.clear()
            navigate({ pathname: '/faq' })
            toast.success(response.data.message, {
                id: "submit"
            })
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nProgress.done()
            toast.error(error.response.data.message, {
                ariaProps: {
                    superscript: error.response.status
                },
                id: "submit"
            })
            console.log(error.response)
        })
    }

    return (
        <Form errors={errors} onSubmit={handleSubmit} data={location.state?.data}/>
    )
}