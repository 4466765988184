import { useEffect, useState } from 'react'
import { Modal } from 'components'
import Label from 'components/forms/label'
import Input from 'components/forms/input'
import InputAmount from 'components/forms/input-amount'
import 'react-datepicker/dist/react-datepicker.css'
import InputDate from 'components/forms/input-date'
import moment from 'moment'
import { lang } from "config"
import { getData } from 'helpers/api-helper'

const Content = ({ data, errors, onSubmit, onRemove }) => {
    const [serial_number, set_serial_number] = useState(data.serial_number)
    const [customer_code, set_customer_code] = useState(data.customer_code)
    const [model, set_model] = useState(data.model)
    const [device_type, set_device_type] = useState(data.device_type)
    const [from, setFrom] = useState(data.from)
    const [to, setTo] = useState(data.to)
    const [status, set_status] = useState(data.status)
    
    const [data_models, set_data_models] = useState([]);

    useEffect(() => {
        getModel()
    }, [])

    const getModel = () => {
        getData(`${process.env.REACT_APP_API}/brand-model`, { paginate: "false" }).then((response) => {
            if (response) {
                set_data_models(response.data.data);
            }
        }).catch((e) => console.log(e));
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            serial_number,
            customer_code,
            model,
            device_type,
            from,
            status,
            to
        }

        // Filter before passing data to parent
        onSubmit(Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v != '')))
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                    <Label htmlFor="serial_number" value={'Serial Number'} />
                    <Input onChange={e => set_serial_number(e.target.value)} value={serial_number} id="serial_number" />
                </div>
                <div>
                    <Label htmlFor="customer_code" value={'Customer Code'} />
                    <Input onChange={e => set_customer_code(e.target.value)} value={customer_code} id="customer_code" />
                </div>
                {/* <div>
                    <Label htmlFor="model" value={'Model'} />
                    <select onChange={(e) => set_model(e.target.value)} value={model} name="model" id="model" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        <option value={null} selected disabled>-- Chose Model --</option>
                            {data_models.map((model, index) => (
                                <option key={index} value={model.name}>{model.name}</option>
                            ))}
                    </select>
                </div> */}
                <div>
                    <Label htmlFor="device_type" value={'Device Type?'} />
                    <select onChange={(e) => set_device_type(e.target.value)} value={device_type} name="device_type" id="device_type" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        <option value={null} selected disabled>-- Device Type? --</option>
                            <option value={'android'}>{'Android'}</option>
                            <option value={'chromebook'}>{'Chromebook'}</option>
                            <option value={'windows'}>{'Windows'}</option>
                    </select>
                </div>
                <div>
                    <Label htmlFor="status" value={'Status'} />
                    <select onChange={(e) => set_status(e.target.value)} value={status} name="status" id="status" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        <option value={null} selected disabled>-- Status --</option>
                            <option value={'active'}>{'Active'}</option>
                            <option value={'expired'}>{'Expired'}</option>
                    </select>
                </div>
                <div>
                    <Label htmlFor="from" value={lang.from} />
                    <InputDate id="from" maxDate={Date.parse(to)} onChange={(value) => setFrom(moment(value).format('Y-M-DD'))} value={Date.parse(from)} selected={Date.parse(from)} />
                </div>

                <div>
                    <Label htmlFor="to" value={lang.to} />
                    <InputDate id="to" minDate={Date.parse(from)} onChange={(value) => setTo(moment(value).format('Y-M-DD'))} value={Date.parse(to)} selected={Date.parse(to)} />
                </div>
            </div>
            <div className="flex items-center space-x-2 text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.filter}</span>
                </button>
                <button type="button" onClick={onRemove} className="items-center px-6 py-3 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                    <span>{lang.clear}</span>
                </button>
            </div>
        </form>
    )
}

const Filter = ({ data, errors, onSubmit, onRemove }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (value) => {
        closeModal()
        onSubmit(value)
    }

    const handleRemove = () => {
        closeModal()
        onRemove()
    }

    return (
        <>
            <button onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"></path>
                </svg>
                <span>{lang.filter}</span>
                {Object.keys(data).length > 0 && (
                    <span className="inline-flex w-4 h-4 font-semibold rounded-full text-[0.5rem] justify-center text-neutral-200 bg-neutral-800">
                        {`${Object.keys(data).length}`}
                    </span>
                )}
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.filter} ${lang.data}`} content={<Content errors={errors} onSubmit={handleSubmit} onRemove={handleRemove} data={data} />} />
        </>
    )
}

export default Filter