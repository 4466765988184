import { useState } from "react"

import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import InputImage from "components/forms/input-image"
// import { convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, List, Font } from 'ckeditor5';
// import { SlashCommand } from 'ckeditor5-premium-features';

import 'ckeditor5/ckeditor5.css';
import Checkbox from "components/forms/checkbox"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const id = data?.id
    const [title, set_title] = useState(data?.title ?? null)
    const [description, set_description] = useState(data?.description ?? null)
    const [type, set_type] = useState(data?.type ?? null)
    const [all_checked, set_all_checked] = useState(data?.all_checked ?? null)
    const [android, set_android] = useState(data?.android ?? 0)
    const [windows, set_windows] = useState(data?.windows ?? 0)
    const [source, set_source] = useState(data?.source ?? null)
    const [thumbnail, set_thumbnail] = useState(data?.thumbnail ?? null)
    // const [content_description, set_content_description] = useState(content_description_x ?? "");

    const handleSubmit = (e) => {
        e.preventDefault()
        var validate_yt_url = true;
        console.log(source,"source")
        if(type == 'video' && source){
            var validate_yt_url = validateYouTubeUrl(source)
        }
        if(!validate_yt_url){
            toast.error("The source link does not come from YouTube!")
        }else if(title === null || title === ''){
            toast.error("Title CMS Required!")
        }else if(type === null || type === ''){
            toast.error("Type CMS Required!")
        }else if(android === 0 && windows === 0){
            toast.error("Device Type CMS Required!")
        }else{
            if (!nProgress.isStarted()) {
                onSubmit({
                    id,
                    title,
                    description,
                    type,
                    android,
                    windows,
                    thumbnail,
                    source
                })
            } else {
                toast.error("Previous action is loading")
            }
        }
    }

    function validateYouTubeUrl(url)
        {
                if (url != undefined || url != '' || url != null) {
                    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    var match = url.match(regExp);
                    if (match && match[2].length == 11) {
                        return true
                    }
                    else {
                        return false
                    }
                }
        }

    // const onEditorStateChange=(editorState)=>{
    //     console.log(editorState)
    //     set_content_description(editorState)
    //     set_description(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    // }

    const changeTextEditor=(editor)=>{
        console.log(editor.getData())
        set_description(editor.getData())
    }

    const handleChangeAndroid = () => {
        if (android === 0) {
            set_android(1)
        } else {
            set_android(0)
            set_all_checked(0)
        }
    }

    const handleChangeWindows = () => {
        if (windows === 0) {
            set_windows(1)
        } else {
            set_windows(0)
            set_all_checked(0)
        }
    }

    const handleChangeAll = () => {
        if (all_checked === 0) {
            set_all_checked(1)
            set_windows(1)
            set_android(1)
        } else {
            set_all_checked(0)
            set_windows(0)
            set_android(0)
        }
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="title" value={'Title'} />
                                        <Input placeholder="Input Title" onChange={(e) => set_title(e.target.value)} value={title} id="title" error={errors.title} />
                                    </div>
                                    <div>
                                        <Label htmlFor="type" value="Type" />
                                        <select onChange={(e) => set_type(e.target.value)} value={type} name="type" id="type" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- {lang.choose} Type --</option>
                                                <option value={'video'}>{'Video'}</option>
                                                <option value={'article'}>{'Article'}</option>
                                        </select>
                                    </div>
                                    <div>
                                        <Label htmlFor="device_type" value="Device Type" />
                                        <div className="flex justify-between text-md my-5">
                                            <div className="flex items-end">
                                                <div>
                                                <Checkbox onChange={handleChangeAll} checked={all_checked} id="all_checked" name="all_checked" />
                                                </div>
                                                <div className="ml-2">
                                                    <label htmlFor="all_checked">
                                                        All
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex items-end">
                                                <div>
                                                <Checkbox onChange={handleChangeWindows} checked={windows} id="windows" name="windows" />
                                                </div>
                                                <div className="ml-2">
                                                    <label htmlFor="windows">
                                                        Windows
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex items-end">
                                                <div>
                                                <Checkbox onChange={handleChangeAndroid} checked={android} id="android" name="android" />
                                                </div>
                                                <div className="ml-2">
                                                    <label htmlFor="android">
                                                        Android
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Label htmlFor="source" value={'Source'} />
                                        <textarea type={'textarea'} placeholder="Input Source" onChange={(e) => set_source(e.target.value)} value={source} id="source" className="border-neutral-200 placeholder:text-neutral-500 file:border-solid file:border file:px-3 file:text-xs file:border-neutral-200 file:py-1 file:rounded-full block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200"/>
                                    </div>
                                    <div>
                                        <Label htmlFor="thumbnail" value={'Thumbnail'} />
                                        <InputImage onChange={(value) => set_thumbnail(value)} aspect="video" id="image" name="image" src={thumbnail} error={errors.image} alt="https://placehold.co/400x400/F5F5F5/404040?font=source-sans-pro&text=400x400" />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 mt-4">
                                    <div>
                                        <Label htmlFor="description" value={'Description'} />
                                        {/* <textarea type={'textarea'} placeholder="Input Description" onChange={(e) => set_description(e.target.value)} value={description} id="description" className="border-neutral-200 placeholder:text-neutral-500 file:border-solid file:border file:px-3 file:text-xs file:border-neutral-200 file:py-1 file:rounded-full block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200"/> */}
                                        {/* <Editor
                                            editorState={content_description}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                        /> */}
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            onChange={( event, editor ) => changeTextEditor(editor)}
                                            onInit = { editor => { 
                                                editor.setData(description)
                                             } }
                                            data={description ?? ""}
                                            config={ {
                                                fontFamily: {
                                                    options: [
                                                        'default', 'Arial',
                                                        'Times New Roman',
                                                        'Courier New','Georgia'
                                                    ]
                                                },
                                                toolbar: {
                                                    items: [ 
                                                        'undo', 'redo',
                                                        '|',
                                                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                                        '|',
                                                        'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                                        '|',
                                                        'link', 'uploadImage', 'blockQuote', 'codeBlock',
                                                        '|',
                                                        'alignment',
                                                        '|',
                                                        'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent' ],
                                                },
                                                plugins: [
                                                    Bold, Essentials, Italic, Mention, Paragraph, Undo, Font
                                                ],
                                                licenseKey: '<YOUR_LICENSE_KEY>',
                                                mention: { 
                                                    // Mention configuration
                                                },
                                                initialData: '',
                                            } }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}